var React = require("react");

var ModalMixin = require("./mixins/Modal");

var Groups = require("./Groups.react");

var PreferencesStore = require("../stores/PreferencesStore");
var PreferencesConstants = require("../constants/PreferencesConstants");

var CalendarStore = require("../stores/CalendarStore");
var CalendarConstants = require("../constants/CalendarConstants");
var CalendarActionCreators = require("../actions/CalendarActionCreators");

var ShowingEditor = React.createClass({
  mixins: [ModalMixin],
  _getState: function() {
    return {
      shouldShowEditor: CalendarStore.shouldShowEditor(),
      showingBeingEdited: CalendarStore.getShowingBeingEdited(),
      method: PreferencesStore.getPreferences().get(PreferencesConstants.Keys.METHOD)
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  componentWillMount: function() {
    if(this.state.shouldShowEditor)
      this.lockHTML();
  },
  componentDidMount: function() {
    CalendarStore.addChangeListener(this.onStoreChange);
    PreferencesStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    CalendarStore.removeChangeListener(this.onStoreChange);
    PreferencesStore.removeChangeListener(this.onStoreChange);
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    if(this.state.showingBeingEdited === nextState.showingBeingEdited &&
        this.state.shouldShowEditor === nextState.shouldShowEditor &&
        this.state.method === nextState.method)
      return false;

    if((!this.state.shouldShowEditor && nextState.shouldShowEditor) ||
        (!this.state.showingBeingEdited && nextState.showingBeingEdited))
      this.lockHTML();
    else if((this.state.shouldShowEditor && !nextState.shouldShowEditor) ||
              (this.state.showingBeingEdited && !nextState.showingBeingEdited))
      this.unlockHTML();
    return true;
  },
  render: function() {
    var showingEditorClassName = "calendar-showing-editor modal method-" + this.state.method;

    var main_name, epno;
    var releaseDate;
    var simulcastLinks;
    if(this.state.showingBeingEdited) {
      main_name = this.state.showingBeingEdited.get("main_name");
      epno = this.state.showingBeingEdited.getIn(["episode", "episode_number"]);

      if(this.state.method === PreferencesConstants.MethodStates.STANDARD)
        releaseDate = "Air date: " + this.state.showingBeingEdited.getIn(["episode", "date_in_moment"]).format("LLL");
      else if(this.state.method === PreferencesConstants.MethodStates.SIMULCASTS)
        releaseDate = "Release date: " + this.state.showingBeingEdited.getIn(["episode", "date_in_moment"]).format("LLL");

      var simulcasts = this.state.showingBeingEdited.getIn(["episode", "simulcasts"]);
      if(simulcasts && simulcasts.size) {
        simulcastLinks = simulcasts.reverse().map(function(simulcast) {
          var simulcast_url = simulcast.get("url");
          if(simulcast_url) {
            var simulcast_origin = simulcast.get("origin");
            var simulcastLinkClassName = "button simulcast-link " + simulcast_origin;

            return <a
              className={simulcastLinkClassName}
              href={simulcast_url}
              target="_blank"
              key={simulcast_origin}>
                <i className={"weeb-" + simulcast_origin}></i>
                Watch this episode on {CalendarConstants.SimulcastOriginNames[simulcast_origin]}
              </a>;
          }
        });
      }
    }
    var watchingClassName = "button toggle-button watching-toggle-button" + (this.state.showingBeingEdited && this.state.showingBeingEdited.get("watching") ? " toggled" : "");
    var watchedClassName = "button toggle-button watched-toggle-button" + (this.state.showingBeingEdited && this.state.showingBeingEdited.getIn(["episode", "watched"]) ? " toggled" : "");
    return (
      <div className={showingEditorClassName}>
        <div className="modal-mobile-buttons">
          <button type="button" className="modal-mobile-close button" onClick={this.hide}><i className="fa fa-close"></i> Close</button>
        </div>
        <div className="modal-backdrop" onClick={this.hide}></div>
        <div className="modal-content clearfix">
          <button type="button" className="modal-desktop-close button icon-button" onClick={this.hide}><i className="fa fa-close"></i></button>
          <h3 className="strong header">{main_name} #{epno}</h3>
          <h5 className="header">{releaseDate}</h5>
          {simulcastLinks}
          <h5 className="setting-label">Settings</h5>
          <button type="button" className={watchingClassName} onClick={this._toggleWatching}>
            <span className="fa-stack watching-icons">
              <i className="fa fa-eye fa-stack-1x"></i>
              <i className="fa fa-repeat fa-stack-1x"></i>
            </span>
            Watching {main_name}?
          </button>
          <button type="button" className={watchedClassName} onClick={this._toggleWatched}>
            <i className="fa fa-eye"></i>
            Watched {main_name} #{epno}?
          </button>
          <div className="groups-setting">
            <h5 className="setting-label">Preferred Sub Groups</h5>
            <Groups showing={this.state.showingBeingEdited}></Groups>
          </div>
        </div>
      </div>
    );
  },
  _toggleWatching: function(event) {
    event.stopPropagation();
    CalendarActionCreators.toggleWatching(this.state.showingBeingEdited);
  },
  hide: function() {
    CalendarActionCreators.closeShowingEditor();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = ShowingEditor;

var React = require("react");

var moment = require("moment");

var config = require("../config");

var TimeMarker = React.createClass({
  propTypes: {
    marker: React.PropTypes.object.isRequired,
    hide: React.PropTypes.bool.isRequired
  },
  shouldComponentUpdate: function(nextProps) {
    return !this.props.hide || !nextProps.hide;
  },
  render: function() {
    var markerDay = moment(this.props.marker).utcOffset(config.utcOffset).startOf('day');
    var markerClass = "calendar-time";
    if(this.props.hide)
      markerClass += " hide";
    if(this.props.marker.seconds() % 2 === 0)
      markerClass += " tick";
    else
      markerClass += " tock";

    var percentage = ((this.props.marker - markerDay) / (24 * 60 * 60 * 1000) * 100);
    var markerStyle;
    if(this.props.marker.hours() >= 16)
      markerStyle = {right: (100 - percentage) + "%"};
    else
      markerStyle = {left: percentage + "%"};
    return (
      <div className={markerClass}>
        <div className="calendar-time-marker" style={markerStyle}></div>
        <div className="calendar-time-tag"    style={markerStyle}>
          {this.props.marker.format("HH")}
          <span className="colon">:</span>
          {this.props.marker.format("mm")}
        </div>
      </div>
    );
  }
});

module.exports = TimeMarker;

var React = require("react");
var ReactDOM = require("react-dom");
var _ = require("underscore");
var Immutable = require("immutable");

var Calendar = require("./components/Calendar.react");
var ShowingEditor = require("./components/ShowingEditor.react");
var CalendarActionCreators = require("./actions/CalendarActionCreators");

var Preferences = require("./components/Preferences.react");
var PreferencesConstants = require("./constants/PreferencesConstants");
var PreferencesStore = require("./stores/PreferencesStore");
var PreferencesActionCreators = require("./actions/PreferencesActionCreators");

var UserBar = require("./components/UserBar.react");
var LoginModal = require("./components/LoginModal.react");

var config = require("./config");
var animeSession = require("animerss-session");

var idsToComponentClasses = {
  'calendar': Calendar,
  'showingEditor': ShowingEditor,
  'preferences': Preferences,
  'userBar': UserBar,
  'loginModal': LoginModal
};

_.each(idsToComponentClasses, function(ComponentClass, elementID) {
  var $element = document.getElementById(elementID);
  if($element) {
    ReactDOM.render(
      <ComponentClass />,
      $element
    );
  }
});

var prefs = _.extend(PreferencesStore.getPreferences().toJS(),
  !!(typeof window !== 'undefined' && window.localStorage) &&
    window.localStorage['preferences'] &&
    JSON.parse(window.localStorage['preferences']));
if(!animeSession.user && prefs[PreferencesConstants.Keys.FILTER] === PreferencesConstants.FilterStates.WATCHING)
  prefs[PreferencesConstants.Keys.FILTER] = PreferencesConstants.FilterStates.ALL;
PreferencesActionCreators.save(Immutable.fromJS(prefs));
CalendarActionCreators.load();

setInterval(function() {
  CalendarActionCreators.load();
}, 300000);
setInterval(function() {
  CalendarActionCreators.updateMarker();
}, config.mobileDetect.mobile() ? 15000 : 1000);

var React = require('react');
var dom = require("../dom");

var Group = require("./Group.react");
var GroupSearchResult = require("./GroupSearchResult.react");

var CalendarActionCreators = require("../actions/CalendarActionCreators");
var GroupSearchConstants = require("../constants/GroupSearchConstants");
var GroupSearchStore = require("../stores/GroupSearchStore");
var GroupUtil = require("../util/groups");

var Groups = React.createClass({
  propTypes: {
    showing: React.PropTypes.object,
    searchDelay: React.PropTypes.number
  },
  _getState: function() {
    return {
      search_term: GroupSearchStore.getSearchTerm(),
      show_search_results: GroupSearchStore.shouldShowSearchResults(),
      search_state: GroupSearchStore.getSearchState(),
      search_results: GroupSearchStore.getSearchResults()
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  getDefaultProps: function() {
    return {
      searchDelay: 50,
    };
  },
  componentDidMount: function() {
    GroupSearchStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    GroupSearchStore.removeChangeListener(this.onStoreChange);
  },
  componentDidUpdate: function() {
    if(this._didAddNewGroup) {
      this.focusOnAdder();
      this._didAddNewGroup = false;
    }
  },
  render: function() {
    var component = this;
    var groups = component.props.showing && component.props.showing.get("groups");
    var groupNodes;
    if(groups) {
      groupNodes = groups.map(function renderGroup(group, index) {
        var adderProps = {};
        if(group.get("$new")) {
          adderProps.ref = "adder";
          adderProps.shouldEditAdder = function() {
            return component.shouldEditAdder();
          };
        }

        return <Group
          key={index}
          showing={component.props.showing}
          group={group}
          placeholder={component.props.placeholder}
          {...adderProps} />;
      });
    }

    var className = "groups-container";
    if(component.state.show_search_results)
      className += " results-below";
    var searchResults = component.state.search_results;
    var suggestions = searchResults.map(function renderResults(result) {
      return (
        <GroupSearchResult
          key={result.get("id")}
          result={result}
          searchTerm={component.state.search_term}
          onMouseDown={component.onResultMouseDown}
          onMouseUp={component.onResultMouseUp}>
        </GroupSearchResult>
      );
    });

    return (
      <div className={className}>
        <div
          role="groups"
          className="groups addable delible"
          onKeyDown={this.onKeyDown}
          onMouseDown={this.onMouseDown}
          onClick={this.onClick}
          onFocus={this.onFocus}
          onScroll={this.onScroll}
          onInput={this.onGroupInput}
          tabIndex="-1"
          aria-label="Groups">
            <div
              aria-hidden
              className="groups-inner">
                {groupNodes}
            </div>
        </div>
        <div className="groups-results">
          {suggestions}
        </div>
      </div>
    );
  },
  onKeyDown: function(event) {
    if(event._didAddNewGroup)
      this._didAddNewGroup = true;
  },
  onMouseDown: function(event) {
    event.preventDefault();
  },
  onClick: function(event) {
    event.preventDefault();
    this.focusOnAdder();
  },
  onScroll: function() {
    if(this._didFocusOnAdder) {
      var node = dom.ReactDOM.findDOMNode(this);
      if(node.scrollTop === 0)
        node.scrollTop = node.scrollHeight;
      this._didFocusOnAdder = false;
    }
  },
  onFocus: function(event) {
    this._didFocusOnAdder = event._didFocusOnAdder;
    if(event._didFocusOnAdder)
      this.refreshSearchResults(event.target.textContent);
  },
  onResultMouseDown: function() {
    this._result_mouse_down = true;
    // If user mouses up anywhere else, then close our search results
    document.addEventListener("mouseup", this._onDocumentMouseUp);
  },
  shouldEditAdder: function() {
    return !this._result_mouse_down;
  },
  _onDocumentMouseUp: function() {
    this._result_mouse_down = false;
    CalendarActionCreators.hideGroupSearchResults();
    document.removeEventListener("mouseup", this._onDocumentMouseUp);
  },
  onResultMouseUp: function(event, group) {
    this._result_mouse_down = false;
    event.stopPropagation();
    CalendarActionCreators.addGroup(this.props.showing, group);
    document.removeEventListener("mouseup", this._onDocumentMouseUp);
  },
  onGroupInput: function(event) {
    this.refreshSearchResults(event.target.textContent);
  },
  refreshSearchResults: function(text) {
    var component = this;
    if(component.state.search_state !== GroupSearchConstants.SearchStates.SEARCHING) {
      component._search_text = GroupUtil.filterGroup(text);
      if(!component._search_timeout) {
        component._search_timeout = setTimeout(function() {
          CalendarActionCreators.searchGroups(component.props.showing, component._search_text);
          component._search_timeout = null;
        }, component.props.searchDelay);
      }
    }
  },
  focusOnAdder: function() {
    if(this.refs.adder)
      this.refs.adder.focusOnGroup();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = Groups;

var React = require("react");

var ModalMixin = require("./mixins/Modal");

var SessionStore = require("../stores/SessionStore");
var SessionConstants = require("../constants/SessionConstants");
var SessionActionCreators = require("../actions/SessionActionCreators");

var LoginModal = React.createClass({
  mixins: [ModalMixin],
  _getState: function() {
    return {
      state: SessionStore.getState(),
      is_modal_open: SessionStore.isLoginModalOpen()
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  componentWillMount: function() {
    if(this.state.shouldShowEditor)
      this.lockHTML();
  },
  componentDidMount: function() {
    SessionStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    SessionStore.removeChangeListener(this.onStoreChange);
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    if(!this.state.is_modal_open && nextState.is_modal_open)
      this.lockHTML();
    else if(this.state.is_modal_open && !nextState.is_modal_open)
      this.unlockHTML();
    return true;
  },
  render: function() {
    return (
      <div className="login-modal modal">
        <form onSubmit={this.submit}>
          <div className="modal-mobile-buttons">
            <button type="button" className="modal-mobile-close button" onClick={this.hide}><i className="fa fa-close"></i> Close</button>
            <button
              className="modal-mobile-save button"
              disabled={this.state.state === SessionConstants.States.LOGGING_IN}>
                {
                  this.state.state === SessionConstants.States.LOGGING_IN ?
                    <i className="fa fa-spin fa-spinner"></i> :
                    <i className="fa fa-sign-in"></i>
                }
                &nbsp;Submit
            </button>
          </div>
          <div className="modal-backdrop" onClick={this.hide}></div>
          <div className="modal-content clearfix">
            <button type="button" className="modal-desktop-close button icon-button" onClick={this.hide}><i className="fa fa-close"></i></button>
            <h3 className="strong header">Login</h3>
            <input type="email" required="required" placeholder="E-Mail" onChange={this.changeEMail} />
            <input type="password" required="required" placeholder="Password"  onChange={this.changePassword} />
            <button
              className="modal-desktop-save button"
              disabled={this.state.state === SessionConstants.States.LOGGING_IN}>
                {
                  this.state.state === SessionConstants.States.LOGGING_IN ?
                    <i className="fa fa-spin fa-spinner"></i> :
                    <i className="fa fa-sign-in"></i>
                }
                &nbsp;Submit
            </button>
          </div>
        </form>
      </div>
    );
  },
  changeEMail: function(event) {
    this.setState({email: event.target.value});
  },
  changePassword: function(event) {
    this.setState({password: event.target.value});
  },
  submit: function(event) {
    event.preventDefault();
    SessionActionCreators.login(this.state.email, this.state.password);
  },
  hide: function() {
    SessionActionCreators.closeLogin();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = LoginModal;

var keyMirror = require('keymirror');

module.exports = {
  ActionTypes: keyMirror({
    LOAD_START: null,
    LOAD_SUCCESS: null,
    LOAD_FAIL: null,
    SET_WATCHING: null,
    SET_WATCHED: null,
    OPEN_EDITOR: null,
    CLOSE_EDITOR: null,
    ADD_GROUP: null,
    REMOVE_GROUP: null,
    EDIT_GROUP_ADDER: null,
    UPDATE_MARKER: null
  }),
  SimulcastOriginNames: {
    'crunchyroll': "Crunchyroll",
    'funimation': "FUNimation",
    'daisuki': "DAISUKI",
    'hulu': "Hulu"
  },
  LoadStates: keyMirror({
    NOT_LOADING: null,
    LOADING: null,
    LOAD_SUCCEEDED: null,
    LOAD_FAILED: null
  })
};

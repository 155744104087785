var superagent = require("superagent");

var execAjax = require("./exec-ajax");

module.exports = {
  search: function(anime_id, text, callback) {
    return execAjax(
      superagent
        .get("/anime/" + anime_id + "/groups/search/" + text),
      callback
    );
  }
};

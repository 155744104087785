var keyMirror = require('keymirror');

module.exports = {
  ActionTypes: keyMirror({
    OPEN_LOGIN_MODAL: null,
    CLOSE_LOGIN_MODAL: null,

    LOGIN_START: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAIL: null,
    LOGOUT_START: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAIL: null
  }),
  States: keyMirror({
    NOT_LOGGED_IN: null,
    LOGGING_IN: null,
    LOGIN_FAILED: null,
    LOGGED_IN: null,
    LOGGING_OUT: null,
    LOGOUT_FAILED: null,
  })
};

var React = require("react");
var moment = require("moment");

var util = require("../util");

var PreferencesConstants = require("../constants/PreferencesConstants");

var CalendarActionCreators = require("../actions/CalendarActionCreators");

var Showing = React.createClass({
  propTypes: {
    showing: React.PropTypes.object.isRequired,
    marker: React.PropTypes.object.isRequired,
    preferences: React.PropTypes.object.isRequired
  },
  render: function() {
    var component = this;
    var showingDate = component.props.showing.getIn(["episode", "date_in_moment"]);
    var duration = component.props.showing.getIn(["episode", "duration"]);

    var styles = {
      left: ((showingDate - moment(showingDate).startOf("day")) / (24 * 60 * 60 * 1000) * 100) + "%",
      width: (duration / (24 * 60) * 100) + "%"
    };

    var epno = component.props.showing.getIn(["episode", "episode_number"]);
    var is_finale = component.props.showing.getIn(["episode", "finale"]);

    var className = "calendar-showing";
    if(component.props.showing.get("watching"))
      className += " watching";
    else if(epno <= 3)
      className += " new-show";

    if(is_finale)
      className += " finale";
    if(component.props.showing.getIn(["episode", "downloaded"]))
      className += " downloaded";
    if(component.props.showing.getIn(["episode", "watched"]))
      className += " watched";

    var eta;
    if(component.props.marker.isBefore(showingDate)) {
      eta = <span className="eta">{util.getDiffString(showingDate, component.props.marker)}</span>;
    } else if(component.props.preferences.get(PreferencesConstants.Keys.METHOD) === PreferencesConstants.MethodStates.STANDARD) {
      var endDate = moment(showingDate).add(duration, 'minutes');
      if(component.props.marker.isBefore(endDate))
        eta = <span className="airing">Airing, {util.getDiffString(endDate, component.props.marker)} left</span>;
    }

    var title;
    if(component.props.showing.has("mal_id")) {
      title = <a className="show-name" href={"http://myanimelist.net/anime/" + component.props.showing.get("mal_id")} target="_blank" onClick={component.onLinkClick}>{component.props.showing.get("main_name")}</a>;
    } else {
      title = <span className="show-name">{component.props.showing.get("main_name")}</span>;
    }

    var simulcastLink;
    var simulcasts = component.props.showing.getIn(["episode", "simulcasts"]);
    if(simulcasts && simulcasts.size) {
      var earliest_simulcast = simulcasts.last();
      simulcastLink = <a
        className="simulcast-link"
        href={earliest_simulcast.get("url")}
        key={earliest_simulcast.get("origin")}
        target="_blank"
        onClick={component.onLinkClick}>
          <i className={"weeb-" + earliest_simulcast.get("origin")}></i>
        </a>;
    }
    return (
      <div className={className}>
        <div className="calendar-showing-info" onClick={component.onClick}>
          <span className="show-icons">
            <span className="watching-icons fa-stack" onClick={component._toggleWatching} title="Watching?">
              <i className="fa fa-eye fa-stack-1x"></i>
              <i className="fa fa-repeat fa-stack-1x"></i>
            </span>
            <i className="fa download-icon fa-download"></i>
            {simulcastLink}
            <i className="fa watched-icon fa-eye" onClick={component._toggleWatched} title="Watched episode?"></i>
          </span>
          <span className="show-time">{showingDate.format("HH:mm")}</span>
          <span className="show-name-epno">
            {title}
            &nbsp;
            <span className="epno">#{epno}</span>
            &nbsp;
            <span className="note">{is_finale ? "(finale)" : ""}</span>
          </span>
          {eta}
        </div>
        <div className="calendar-showing-time" style={styles}></div>
      </div>
    );
  },
  _toggleWatching: function(event) {
    event.stopPropagation();
    CalendarActionCreators.toggleWatching(this.props.showing);
  },
  _toggleWatched: function(event) {
    event.stopPropagation();
    CalendarActionCreators.toggleWatched(this.props.showing);
  },
  onLinkClick: function(event) {
    event.stopPropagation();
  },
  onClick: function() {
    CalendarActionCreators.editShowing(this.props.showing);
  }
});

module.exports = Showing;

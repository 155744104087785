var keyMirror = require('keymirror');

var FilterStates = {
  ALL: "all",
  WATCHING: "watching",
  NEW_SHOWS: "new-shows"
};
var MethodStates = {
  STANDARD: "standard",
  SIMULCASTS: "simulcasts"
};

module.exports = {
  ActionTypes: keyMirror({
    SAVE: null,
    SHOW: null,
    HIDE: null
  }),
  Keys: {
    FILTER: "filter",
    SCROLL_TO_TODAY: "scroll_to_today",
    METHOD: "method"
  },
  FilterStates: FilterStates,
  FilterButtons: [{
    label: "All Shows",
    iconClass: "fa fa-asterisk",
    value: FilterStates.ALL
  }, {
    label: "Shows I'm Watching",
    iconClass: "fa fa-eye",
    value: FilterStates.WATCHING
  }, {
    label: "New Shows",
    iconClass: "fa fa-exclamation",
    value: FilterStates.NEW_SHOWS
  }],
  MethodStates: MethodStates,
  MethodButtons: [{
    label: "TV Air Time",
    iconClass: "fa fa-television",
    value: MethodStates.STANDARD
  }, {
    label: "Simulcasts",
    iconClass: "fa fa-wifi",
    value: MethodStates.SIMULCASTS
  }]
};

var React = require("react");

var CalendarActionCreators = require("../actions/CalendarActionCreators");

var GroupUtil = require("../util/groups");
var dom = require("../dom");

var Group = React.createClass({
  propTypes: {
    showing: React.PropTypes.object.isRequired,
    group: React.PropTypes.object.isRequired,
    placeholder: React.PropTypes.string,
    shouldEditAdder: React.PropTypes.func
  },
  getDefaultProps: function() {
    return {
      placeholder: "",
      shouldEditAdder: function() { return true; }
    };
  },
  getInitialState: function() {
    return {
      has_avatar: false
    };
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    var groupNode = this._findEditableNode();

    var currentText = groupNode.textContent;
    var nextName = nextProps.group.get("name");
    return (document.activeElement !== groupNode && currentText !== nextName) ||
      (this.props.group !== nextProps.group) ||
      (this.state.has_avatar !== nextState.has_avatar);
  },
  render: function() {
    var groupName = this.props.group.get("name");

    var containerClassName = "group-container";
    if(this.state.has_avatar)
      containerClassName += " has-avatar";

    var className = "group";
    if(this.props.group.get("$new"))
      className += " group-adder";
    if(this.props.group.get("$invalid"))
      className += " invalid";
    return (
      <span
        className={containerClassName}>
          <span
            role="group"
            aria-label={this.props.group.get("$new") ? "New Group" : ('group "' + groupName + '"')}
            className={className}
            onCut={this.onCut}
            onPaste={this.onPaste}
            onInput={this.onInput}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onMouseDown={this.onMouseDown}
            onClick={this.onClick}
            contentEditable={this.props.group.get("$new") ? GroupUtil.getContentEditableTrueValue() : "false"}
            spellCheck="false"
            tabIndex="1">
              {groupName}
          </span>
          <span className="group-after"></span>
          <span
            aria-hidden={this.props.group.get("$new")}
            aria-label="Delete group"
            role="delete-group"
            className="group-delete-button"
            onClick={this.onDeleteButtonClick}
            tabIndex="1"></span>
          <span
            className="groups-placeholder"
            role="group-placeholder"
            aria-label={this.props.placeholder}
            onClick={this.onClick}
            tabIndex="-1">
              {this.props.placeholder}
          </span>
      </span>
    );
  },
  /*
    In Chrome, when the group span is empty, then the caret doesn't show up
    correctly. This is a problem in Chrome in particular. To counter that,
    we make it so the group always has a character, and we happen to make it
    a zero-width character
   */
  chromeCaretFix: function() {
    if(this.props.group.get("$new")) {
      var groupNode = this._findEditableNode();
      if(groupNode.textContent === "")
        groupNode.textContent = "\ufeff";
    }
  },
  focusOnGroup: function() {
    this.chromeCaretFix();
    GroupUtil.focusEndOfContentEditable(this._findEditableNode());
  },
  clearAdder: function() {
    if(!this.props.group.get("$new"))
      throw new Error("clearAdder called on non-adder");
    this._findEditableNode().textContent = "";
  },
  onCut: function(event) {
    if(!this.props.group.get("$new"))
      return event.preventDefault();
  },
  onPaste: function(event) {
    if(!this.props.group.get("$new"))
      return event.preventDefault();
  },
  onInput: function(event) {
    if(!this.props.group.get("$new"))
      return event.preventDefault();

    this.chromeCaretFix();
  },
  onFocus: function(event) {
    if(this.props.group.get("$new")) {
      event._didFocusOnAdder = true;

      var groupNode = this._findEditableNode();
      if(groupNode.textContent === "") {
        this.chromeCaretFix();
        GroupUtil.focusEndOfContentEditable(groupNode);
      }
    }
  },
  onBlur: function(event) {
    if(this.props.group.get("$new"))
      event._didBlurFromAdder = true;

    var groupNode = this._findEditableNode();
    var text = groupNode.textContent;
    var filteredText = GroupUtil.filterGroup(text);

    if(!this.props.group.get("$new")) {
      if(filteredText === "") {
        this._delete();
      }
    } else {
      this._add(filteredText);
    }
  },
  onKeyDown: function(event) {
    if(!this.props.group.get("$new"))
      return event.preventDefault();

    if(event.keyCode === 13) {
      event.preventDefault();

      if(this.props.group.get("$new"))
        event._didAddNewGroup = true;
      else
        event._didEditGroup = true;

      this._findEditableNode().blur();
    }
  },
  onMouseDown: function(event) {
    event.stopPropagation();
  },
  onClick: function(event) {
    event.stopPropagation();
  },
  onDeleteButtonClick: function(event) {
    event.stopPropagation();
    this._delete();
  },
  _add: function(filteredText) {
    if(this.props.shouldEditAdder())
      CalendarActionCreators.editGroupAdder(this.props.showing, filteredText);

    // We might have some trash left (the Chrome caret fix, so clear it)
    if(filteredText === "")
      this.clearAdder();
  },
  _delete: function() {
    CalendarActionCreators.removeGroup(this.props.showing, this.props.group);
  },
  _findEditableNode: function() {
    return dom.ReactDOM.findDOMNode(this).children[0];
  }
});

module.exports = Group;

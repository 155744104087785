var superagent = require("superagent");

var PreferencesConstants = require("../constants/PreferencesConstants");
var PreferencesStore = require("../stores/PreferencesStore");

var config = require("../config");
var execAjax = require("./exec-ajax");

module.exports = {
  load: function(callback) {
    var query = {
      offset: config.utcOffset,
      method: PreferencesStore.getPreferences().get(PreferencesConstants.Keys.METHOD)
    };
    return execAjax(
      superagent
        .get("/calendar")
        .query(query),
      callback
    );
  },
  setWatching: function(id, watching, callback) {
    return execAjax(
      superagent
        .post("/anime/" + id + "/watching")
        .send({watching: watching}),
      callback
    );
  },
  setWatched: function(id, epno, watched, callback) {
    return execAjax(
      superagent
        .post("/anime/" + id + "/watched/" + epno)
        .send({watched: watched}),
      callback
    );
  },
  addGroup: function(id, group_id, callback) {
    return execAjax(
      superagent
        .post("/anime/" + id + "/group/" + group_id),
      callback
    );
  },
  removeGroup: function(id, group_id, callback) {
    return execAjax(
      superagent
        .del("/anime/" + id + "/group/" + group_id),
      callback
    );
  }
};

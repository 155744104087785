var React = require("react");

var SessionStore = require("../stores/SessionStore");
var SessionConstants = require("../constants/SessionConstants");
var SessionActionCreators = require("../actions/SessionActionCreators");

var UserBar = React.createClass({
  _getState: function() {
    return {
      user: SessionStore.getUser(),
      state: SessionStore.getState()
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  componentDidMount: function() {
    SessionStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    SessionStore.removeChangeListener(this.onStoreChange);
  },
  render: function() {
    return (
      <div className="user-bar">
        <span className="user-message">
        {
          this.state.user ?
            "Hi, " + this.state.user.email + "!" :
            ""
        }
        </span>
        {
          this.state.user ?
            <button
              type="button"
              className="button login-button"
              onClick={this.logout}
              disabled={this.state.state === SessionConstants.States.LOGGING_OUT}>
                {
                  this.state.state === SessionConstants.States.LOGGING_OUT ?
                    <i className="fa fa-spin fa-spinner" /> :
                    <i className="fa fa-sign-out" />
                }
                &nbsp;Logout
            </button> :
            <button type="button" className="button login-button" onClick={this.openLogin}><i className="fa fa-sign-in" /> Login</button>
        }
      </div>
    );
  },
  openLogin: function() {
    SessionActionCreators.openLogin();
  },
  logout: function() {
    SessionActionCreators.logout();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = UserBar;

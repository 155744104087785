var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var Immutable = require('immutable');
var moment = require('moment');

var CalendarDispatcher = require('../dispatchers/CalendarDispatcher');
var CalendarConstants = require('../constants/CalendarConstants');

var config = require('../config');

var CHANGE_EVENT = 'change';

var _load_state = CalendarConstants.LoadStates.LOADING;
var _show_editor = false;
var _calendar = Immutable.List();
var _marker = null;

var _getShowingBeingEdited = function() {
  return _calendar.reduce(function(showingBeingEdited, showingGroup) {
    if(showingBeingEdited)
      return showingBeingEdited;
    return showingGroup.get("showings").find(function(showing) {
      return showing.get("editing");
    });
  }, null);
};

var _showingsToGroup = function(showings) {
  return Immutable.Map({
    newWatchingEpisodes: showings.count(function(showing) {
      return showing.get("watching");
    }),
    newShowsEpisodes: showings.count(function(showing) {
      return showing.get("newshow");
    }),
    lastTime: moment(showings.last().getIn(["episode", "date_in_moment"])).utcOffset(config.utcOffset).endOf('day'),
    showings: showings
  });
};

var _forEachShowing = function(updateFunc) {
  _calendar = _calendar.map(function(showingGroup) {
    var oldShowings = showingGroup.get("showings");
    var newShowings = oldShowings.map(updateFunc);

    if(oldShowings === newShowings)
      return showingGroup;

    return _showingsToGroup(newShowings);
  });
};

var _processResults = function(results) {
  var showingBeingEdited = _getShowingBeingEdited();
  var editKey = showingBeingEdited && showingBeingEdited.get("key");
  var showings = Immutable.fromJS(results).map(function(showing) {
    var key = showing.get("main_name") + "_" + showing.getIn(["episode", "date"]) + "_" + showing.getIn(["episode", "episode_number"]);
    return showing
      .set("open", false)
      .set("editing", key === editKey)
      .set("key", key)
      .set("newshow", !showing.get("watching") && showing.getIn(["episode", "episode_number"]) <= 3)
      .setIn(["episode", "date_in_moment"], moment(showing.getIn(["episode", "date"]) * 1000).utcOffset(config.utcOffset))
      .update("groups", Immutable.List(), function(groups) {
        return groups.push(Immutable.Map({$new: true, id: null}));
      });
  });
  _calendar = showings.groupBy(function(showing) {
    return moment(showing.getIn(["episode", "date_in_moment"])).utcOffset(config.utcOffset).startOf("day");
  }).map(function(showings) {
    return _showingsToGroup(showings);
  });
};

var _editShowing = function(the_showing) {
  _forEachShowing(function(showing) {
    return showing.set("editing", showing === the_showing);
  });
};

var _setWatching = function(id, watching) {
  _forEachShowing(function(showing) {
    if(showing.get("id") !== id)
      return showing;
    return showing.set("watching", watching);
  });
};

var _setWatched = function(the_showing, watched) {
  _forEachShowing(function(showing) {
    if(showing !== the_showing)
      return showing;
    return showing.setIn(["episode", "watched"], watched);
  });
};

var _editGroupAdder = function(the_showing, text) {
  _forEachShowing(function(showing) {
    if(showing !== the_showing)
      return showing;
    var new_showing = showing.update("groups", Immutable.List([Immutable.Map({$new: true, id: null})]), function(groups) {
      return groups.update(groups.size - 1, function(adderGroup) {
        return adderGroup.set("name", text).set("$invalid", !!text);
      });
    });
    return new_showing;
  });
};

var _addGroup = function(id, group) {
  _forEachShowing(function(showing) {
    if(showing.get("id") !== id)
      return showing;
    return showing.update("groups", function(groups) {
      return groups.update(groups.size - 1, function(adder) {
        return adder
          .set("id", group.get("id"))
          .set("name", group.get("name"))
          .set("$new", false)
          .set("$invalid", false);
      }).push(Immutable.Map({$new: true, id: null}));
    });
  });
};

var _removeGroup = function(id, group) {
  _forEachShowing(function(showing) {
    if(showing.get("id") !== id)
      return showing;
    return showing.update("groups", function(groups) {
      return groups.filterNot(function(other_group) {
        return other_group.get("id") === group.get("id");
      });
    });
  });
};

var _updateMarker = function() {
  _marker = moment().utcOffset(config.utcOffset);
};

var CalendarStore = assign({}, EventEmitter.prototype, {
  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  getLoadState: function() {
    return _load_state;
  },
  getCalendar: function() {
    return _calendar;
  },
  shouldShowEditor: function() {
    return _show_editor;
  },
  getShowingBeingEdited: function() {
    return _getShowingBeingEdited();
  },
  getMarker: function() {
    return _marker;
  }
});

CalendarStore.dispatchToken = CalendarDispatcher.register(function CalendarStoreActionHandler(action) {
  switch(action.type) {
    case CalendarConstants.ActionTypes.LOAD_START:
      _load_state = CalendarConstants.LoadStates.LOADING;
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.LOAD_SUCCESS:
      _load_state = CalendarConstants.LoadStates.LOAD_SUCCEEDED;
      _processResults(action.results);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.LOAD_FAIL:
      _load_state = CalendarConstants.LoadStates.LOAD_FAILED;
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.SET_WATCHING:
      _setWatching(action.id, action.watching);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.SET_WATCHED:
      _setWatched(action.showing, action.watched);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.OPEN_EDITOR:
      _show_editor = true;
      _editShowing(action.showing);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.CLOSE_EDITOR:
      _show_editor = false;
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.ADD_GROUP:
      _addGroup(action.id, action.group);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.REMOVE_GROUP:
      _removeGroup(action.id, action.group);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.EDIT_GROUP_ADDER:
      _editGroupAdder(action.showing, action.text);
      _updateMarker();
      CalendarStore.emitChange();
      break;
    case CalendarConstants.ActionTypes.UPDATE_MARKER:
      _updateMarker();
      CalendarStore.emitChange();
      break;
  }
});

module.exports = CalendarStore;

var React = require("react");
var ReactCSSTransitionGroup = React.addons ? React.addons.CSSTransitionGroup : require("react-addons-css-transition-group");

var moment = require("moment");

var dom = require("../dom");
var ReactDOM = dom.ReactDOM;
var fastdom = dom.fastdom;

var PreferencesConstants = require("../constants/PreferencesConstants");

var Showing = require("./Showing.react");
var HourMarkers = require("./HourMarkers.react");
var TimeMarker = require("./TimeMarker.react");

var ShowingGroup = React.createClass({
  propTypes: {
    showingGroup: React.PropTypes.object.isRequired,
    marker: React.PropTypes.object.isRequired,
    preferences: React.PropTypes.object.isRequired
  },
  componentDidMount: function() {
    if(dom.exists &&
        this.props.preferences.get(PreferencesConstants.Keys.SCROLL_TO_TODAY) &&
        moment().isSame(this.props.showingGroup.get("lastTime"), 'day')) {
      var thisElem = ReactDOM.findDOMNode(this);
      fastdom.read(function() {
        var html = document && document.documentElement;
        var rect = thisElem.getBoundingClientRect();
        var scrollTop = Math.floor(html.scrollTop || document.body.scrollTop + rect.top);

        fastdom.write(function() {
          html.scrollTop = scrollTop;
          document.body.scrollTop = scrollTop;
        });
      });
    }
  },
  shouldComponentUpdate: function(nextProps) {
    if(this.props.showingGroup !== nextProps.showingGroup || this.props.preferences !== nextProps.preferences)
      return true;

    if(!this.props.showingGroup)
      return false;

    if(this.props.marker === nextProps.marker)
      return false;

    var lastTime = this.props.showingGroup.get("lastTime");
    return lastTime.isAfter(this.props.marker) || lastTime.isAfter(nextProps.marker);
  },
  render: function() {
    var component = this;
    var className = "calendar-showings-group";

    var showings = component.props.showingGroup.get("showings");
    var day = moment(component.props.showingGroup.get("lastTime")).startOf('day');
    var showingElements = showings.map(function(showing) {
      var key = showing.get("key");
      return <Showing key={key} showing={showing} marker={component.props.marker} preferences={component.props.preferences}/>;
    });

    var hideMarker = !component.props.marker.isSame(day, 'day');

    var newWatchingEpisodes = component.props.showingGroup.get("newWatchingEpisodes");
    var newShowsEpisodes = component.props.showingGroup.get("newShowsEpisodes");
    if(newWatchingEpisodes === 0)
      className += " no-watching-episodes";
    if(newShowsEpisodes === 0)
      className += " no-new-shows-episodes";

    var watchingEpisodesHeader;
    if(newWatchingEpisodes > 1)
      watchingEpisodesHeader = <h3 className="new-episodes">{newWatchingEpisodes} new episodes</h3>;
    else if(newWatchingEpisodes === 1)
      watchingEpisodesHeader = <h3 className="new-episodes">{newWatchingEpisodes} new episode</h3>;

    return (
      <div className={className}>
        <div className="calendar-day-header clearfix">
          <h2 className="dayOfWeek">{day.format("ddd")}</h2>
          <h3 className="date">{day.format("L")}</h3>
          {watchingEpisodesHeader}
        </div>
        <HourMarkers />
        <div className="calendar-showings-container">
          <ReactCSSTransitionGroup transitionName="showing" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
            {showingElements}
          </ReactCSSTransitionGroup>
          <TimeMarker marker={component.props.marker} hide={hideMarker} />
        </div>
      </div>
    );
  }
});

module.exports = ShowingGroup;

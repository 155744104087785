var React = require("react");
var ReactCSSTransitionGroup = React.addons ? React.addons.CSSTransitionGroup : require("react-addons-css-transition-group");

var _ = require("underscore");

var PreferencesStore = require("../stores/PreferencesStore");
var PreferencesConstants = require("../constants/PreferencesConstants");
var PreferencesActionCreators = require("../actions/PreferencesActionCreators");

var CalendarStore = require("../stores/CalendarStore");
var CalendarConstants = require("../constants/CalendarConstants");
var ShowingGroup = require("./ShowingGroup.react");

var Calendar = React.createClass({
  _getState: function() {
    return {
      marker: CalendarStore.getMarker(),
      preferences: PreferencesStore.getPreferences(),
      load_state: CalendarStore.getLoadState(),
      calendar: CalendarStore.getCalendar()
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  componentDidMount: function() {
    PreferencesStore.addChangeListener(this.onStoreChange);
    CalendarStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    PreferencesStore.removeChangeListener(this.onStoreChange);
    CalendarStore.removeChangeListener(this.onStoreChange);
  },
  render: function() {
    var component = this;
    var calendarClass = "calendar";
    switch(component.state.load_state) {
      case CalendarConstants.LoadStates.NOT_LOADING:    calendarClass += " not-loading";  break;
      case CalendarConstants.LoadStates.LOADING:
        calendarClass += " loading";
        if(!component.state.calendar.size)
          calendarClass += " initial-load";
        else
          calendarClass += " reloading";
        break;
      case CalendarConstants.LoadStates.LOAD_SUCCEEDED: calendarClass += " load-success"; break;
      case CalendarConstants.LoadStates.LOAD_FAILED:    calendarClass += " load-failed";  break;
    }

    var filterElements = _.map(PreferencesConstants.FilterButtons, function(filter) {
      var className = "button toggle-button icon-block-button filter-button filter-button-" + filter.value;
      if(filter.value === component.state.preferences.get(PreferencesConstants.Keys.FILTER))
        className += " toggled";
      var onFilterClick = function() {
        PreferencesActionCreators.save(component.state.preferences.set(PreferencesConstants.Keys.FILTER, filter.value));
      };
      return <button
        key={filter.value}
        type="button"
        className={className}
        onClick={onFilterClick}>
          <i className={filter.iconClass}></i>
          {filter.label}
        </button>;
    });
    var filter = component.state.preferences.get(PreferencesConstants.Keys.FILTER);
    if(filter === PreferencesConstants.FilterStates.WATCHING)
      calendarClass += " filter-watching";
    else if(filter === PreferencesConstants.FilterStates.NEW_SHOWS)
      calendarClass += " filter-new-shows";

    calendarClass += " method-" + component.state.preferences.get(PreferencesConstants.Keys.METHOD);

    var showingGroupElements = component.state.calendar.map(function(showingGroups, day) {
      return <ShowingGroup key={day.format("L")} showingGroup={showingGroups} marker={component.state.marker} preferences={component.state.preferences}/>;
    }).toList();

    return (
      <div className={calendarClass}>
        <button type="button" className="calendar-preferences-button mobile button" onClick={this.showPreferences}><i className="fa fa-cog"></i> Preferences...</button>
        <div className="calendar-quick-filters filter-button-group">
          {filterElements}
          <button type="button" className="calendar-preferences-button desktop button" onClick={this.showPreferences}><i className="fa fa-cog"></i></button>
        </div>
        <ReactCSSTransitionGroup transitionName="showings-group" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
          {showingGroupElements}
        </ReactCSSTransitionGroup>
        <div className="calendar-loading-blocker"><div><i className="fa fa-spinner fa-spin"></i> Loading...</div></div>
        <div className="calendar-reloading-indicator"><div><i className="fa fa-spinner fa-spin"></i> Reloading...</div></div>
      </div>
    );
  },
  showPreferences: function() {
    PreferencesActionCreators.show();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = Calendar;

var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');

var dom = require("../dom");

var animeSession = require("animerss-session");

var SessionDispatcher = require('../dispatchers/SessionDispatcher');
var SessionConstants = require('../constants/SessionConstants');

var CHANGE_EVENT = 'change';
var user = animeSession.user;
var state = user ? SessionConstants.States.LOGGED_IN : SessionConstants.States.NOT_LOGGED_IN;
var is_modal_open = false;

var SessionStore = assign({}, EventEmitter.prototype, {
  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  getUser: function() {
    return user;
  },
  getState: function() {
    return state;
  },
  isLoginModalOpen: function() {
    return is_modal_open;
  }
});

SessionStore.dispatchToken = SessionDispatcher.register(function SessionStoreActionHandler(action) {
  switch(action.type) {
    case SessionConstants.ActionTypes.OPEN_LOGIN_MODAL:
      is_modal_open = true;
      SessionStore.emitChange();
      break;
    case SessionConstants.ActionTypes.CLOSE_LOGIN_MODAL:
      is_modal_open = false;
      SessionStore.emitChange();
      break;

    case SessionConstants.ActionTypes.LOGIN_START:
      state = SessionConstants.States.LOGGING_IN;
      SessionStore.emitChange();
      break;
    case SessionConstants.ActionTypes.LOGIN_SUCCESS:
      state = SessionConstants.States.LOGGED_IN;
      user = action.user;
      if(dom.exists) {
        document.body.classList.remove("not-logged-in");
        document.body.classList.add("logged-in");
      }
      is_modal_open = false;
      SessionStore.emitChange();
      break;
    case SessionConstants.ActionTypes.LOGIN_FAIL:
      state = SessionConstants.States.LOGIN_FAILED;
      SessionStore.emitChange();
      break;

    case SessionConstants.ActionTypes.LOGOUT_START:
      state = SessionConstants.States.LOGGING_OUT;
      SessionStore.emitChange();
      break;
    case SessionConstants.ActionTypes.LOGOUT_SUCCESS:
      state = SessionConstants.States.NOT_LOGGED_IN;
      user = null;
      if(dom.exists) {
        document.body.classList.add("not-logged-in");
        document.body.classList.remove("logged-in");
      }
      SessionStore.emitChange();
      break;
    case SessionConstants.ActionTypes.LOGOUT_FAIL:
      state = SessionConstants.States.LOGOUT_FAILED;
      SessionStore.emitChange();
      break;
  }
});

module.exports = SessionStore;

var SessionDispatcher = require('../dispatchers/SessionDispatcher');
var SessionConstants = require('../constants/SessionConstants');
var SessionAPI = require("../api/SessionAPI");

var CalendarActionCreators = require("./CalendarActionCreators");

module.exports = {
  openLogin: function() {
    SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.OPEN_LOGIN_MODAL});
  },
  closeLogin: function() {
    SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.CLOSE_LOGIN_MODAL});
  },
  login: function(email, password) {
    SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGIN_START});
    SessionAPI.login(email, password, function(error, response) {
      if(error)
        return SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGIN_FAIL, error: error});
      SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGIN_SUCCESS, user: response.user});
      CalendarActionCreators.load();
    });
  },
  logout: function() {
    SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGOUT_START});
    SessionAPI.logout(function(error) {
      if(error)
        return SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGOUT_FAIL, error: error});
      SessionDispatcher.dispatch({type: SessionConstants.ActionTypes.LOGOUT_SUCCESS});
      CalendarActionCreators.load();
    });
  }
};

var React = require("react");

var _ = require("underscore");

var HourMarkers = React.createClass({
  shouldComponentUpdate: function() {
    return false;
  },
  render: function() {
    var hourMarkers = _.map(_.range(0, 24), function(hour) {
      var hourMarkerStyles = {
        left: (hour * 100 / 24) + "%"
      };
      var hourMarkerClass = "marker";
      if(hour % 2 === 0)
        hourMarkerClass += " even";
      else
        hourMarkerClass += " odd";
      return <div key={hour} className={hourMarkerClass} style={hourMarkerStyles}>{hour}</div>;
    });
    
    return (
      <div className="calendar-hour-markers">
        {hourMarkers}
      </div>
    );
  }
});

module.exports = HourMarkers;

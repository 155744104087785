var keyMirror = require('keymirror');

module.exports = {
  ActionTypes: keyMirror({
    SEARCH_START: null,
    SEARCH_SUCCESS: null,
    SEARCH_FAIL: null,
    SET_SHOW_RESULTS: null,
    RESET: null
  }),
  SearchStates: keyMirror({
    NOT_SEARCHING: null,
    SEARCHING: null,
    SEARCH_SUCCEEDED: null,
    SEARCH_FAILED: null
  })
};

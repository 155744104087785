// Determine if we support "plaintext-only"
var _content_editable_true_value = "true";
(function() {
  if(typeof document === 'undefined')
    return;
  var d = document.createElement("div");
  try {
    d.contentEditable = "PLAINtext-onLY";
    if(d.contentEditable === "plaintext-only")
      _content_editable_true_value = "plaintext-only";
  } catch(e) {
  }
})();

var _void_node_tags = ['AREA', 'BASE', 'BR', 'COL', 'EMBED', 'HR', 'IMG', 'INPUT', 'KEYGEN', 'LINK', 'MENUITEM', 'META', 'PARAM', 'SOURCE', 'TRACK', 'WBR', 'BASEFONT', 'BGSOUND', 'FRAME', 'ISINDEX'];
var _canContainText = function(node) {
  return node.nodeType === 1 && _void_node_tags.indexOf(node.nodeName) !== -1;
};
var getLastChildElement = function(elem) {
  var lc = elem.lastChild;
  while(lc && lc.nodeType !== 1) {
    if(lc.previousSibling)
      lc = lc.previousSibling;
    else
      break;
  }
  return lc;
};

module.exports = {
  filterGroup: function(group) {
    return group.replace(/\s+/g, " ").replace(/[^\sa-zA-Z0-9\-!?&_]/g, "").trim();
  },
  getContentEditableTrueValue: function() {
    return _content_editable_true_value;
  },
  // Adapted from http://stackoverflow.com/a/19588665
  focusEndOfContentEditable: function(contentEditableElement) {
    contentEditableElement.focus();
    while(getLastChildElement(contentEditableElement) &&
      _canContainText(getLastChildElement(contentEditableElement)))
        contentEditableElement = getLastChildElement(contentEditableElement);

    var range, selection;
    if(document.createRange) {//Firefox, Chrome, Opera, Safari, IE 9+
      range = document.createRange();//Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
      range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection();//get the selection object (allows you to change selection)
      selection.removeAllRanges();//remove any selections already made
      selection.addRange(range);//make the range you have just created the visible selection
    } else if(document.selection) { //IE 8 and lower
      range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
      range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      range.select(); //Select the range (make it the visible selection
    }
  }
};

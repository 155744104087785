module.exports = function(call, callback) {
  callback = callback || function() {};
  
  call
    .set('Accept', 'application/json')
    .end(function(error, res) {
      if(error)
        return callback(error);
      if(res.body.error)
        return callback(res.body.message);
      callback(null, res.body);
    });
};

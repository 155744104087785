var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var Immutable = require('immutable');

var PreferencesDispatcher = require('../dispatchers/PreferencesDispatcher');
var PreferencesConstants = require('../constants/PreferencesConstants');

var CHANGE_EVENT = 'change';
var _supports_localStorage = !!(typeof window !== 'undefined' && window.localStorage);

var _preferences = Immutable.Map()
  .set(PreferencesConstants.Keys.FILTER, PreferencesConstants.FilterStates.ALL)
  .set(PreferencesConstants.Keys.METHOD, PreferencesConstants.MethodStates.STANDARD);
var _is_showing = false;

var _save = function(_new_preferences) {
  _preferences = _new_preferences;
  if(_supports_localStorage)
    window.localStorage['preferences'] = JSON.stringify(_preferences.toJS());
};

var PreferencesStore = assign({}, EventEmitter.prototype, {
  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  getPreferences: function() {
    return _preferences;
  },
  isShowing: function() {
    return _is_showing;
  }
});

PreferencesStore.dispatchToken = PreferencesDispatcher.register(function PreferencesStoreActionHandler(action) {
  switch(action.type) {
    case PreferencesConstants.ActionTypes.SAVE:
      _save(action.preferences);
      _is_showing = false;
      PreferencesStore.emitChange();
      break;
    case PreferencesConstants.ActionTypes.SHOW:
      _is_showing = true;
      PreferencesStore.emitChange();
      break;
    case PreferencesConstants.ActionTypes.HIDE:
      _is_showing = false;
      PreferencesStore.emitChange();
      break;
  }
});

module.exports = PreferencesStore;

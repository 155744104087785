var EventEmitter = require('events').EventEmitter;
var assign = require('object-assign');
var Immutable = require('immutable');

var GroupSearchDispatcher = require('../dispatchers/GroupSearchDispatcher');
var GroupSearchConstants = require('../constants/GroupSearchConstants');

var CHANGE_EVENT = 'change';

var _search_term = "";
var _show_search_results = false;
var _search_state = GroupSearchConstants.SearchStates.NOT_SEARCHING;
var _search_results = Immutable.List();

var GroupSearchStore = assign({}, EventEmitter.prototype, {
  emitChange: function() {
    this.emit(CHANGE_EVENT);
  },
  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  getSearchTerm: function() {
    return _search_term || "";
  },
  shouldShowSearchResults: function() {
    return _show_search_results;
  },
  getSearchState: function() {
    return _search_state;
  },
  getSearchResults: function() {
    return _search_results;
  }
});

GroupSearchStore.dispatchToken = GroupSearchDispatcher.register(function GroupSearchStoreActionHandler(action) {
  switch(action.type) {
    case GroupSearchConstants.ActionTypes.SEARCH_START:
      _search_term = action.term;
      _search_state = GroupSearchConstants.SearchStates.SEARCHING;
      _show_search_results = true;
      GroupSearchStore.emitChange();
      break;
    case GroupSearchConstants.ActionTypes.SEARCH_SUCCESS:
      _search_state = GroupSearchConstants.SearchStates.SEARCH_SUCCEEDED;
      _search_results = Immutable.fromJS(action.results);
      GroupSearchStore.emitChange();
      break;
    case GroupSearchConstants.ActionTypes.SEARCH_FAILED:
      _search_state = GroupSearchConstants.SearchStates.SEARCH_FAILED;
      GroupSearchStore.emitChange();
      break;
    case GroupSearchConstants.ActionTypes.SET_SHOW_RESULTS:
      _show_search_results = action.show;
      GroupSearchStore.emitChange();
      break;
    case GroupSearchConstants.ActionTypes.RESET:
      _search_term = "";
      _show_search_results = false;
      _search_state = GroupSearchConstants.SearchStates.NOT_SEARCHING;
      _search_results = Immutable.List([]);
      GroupSearchStore.emitChange();
      break;
  }
});

module.exports = GroupSearchStore;

var superagent = require("superagent");

var execAjax = require("./exec-ajax");

module.exports = {
  login: function(email, password, callback) {
    return execAjax(
      superagent
        .post("/login")
        .send({email: email, password: password}),
      callback
    );
  },
  logout: function(callback) {
    return execAjax(
      superagent
        .post("/logout"),
      callback
    );
  }
};

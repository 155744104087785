var dom = {};

if(typeof window !== 'undefined' && window.document) {
  dom.ReactDOM = require("react-dom");
  dom.fastdom = require("fastdom");
  dom.exists = true;
} else {
  dom.ReactDOM = {
    findDOMNode: function() {
      return null;
    }
  };
  dom.fastdom = {
    read:  function(cb) { cb(); },
    write: function(cb) { cb(); },
  };
  dom.exists = false;
}

module.exports = dom;

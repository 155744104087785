var _ = require("underscore");
var dom = require("../../dom");
var ReactDOM = dom.ReactDOM;
var fastdom = dom.fastdom;

module.exports = {
  lockHTML: function() {
    if(dom.exists) {
      var thisElem = ReactDOM.findDOMNode(this);

      fastdom.read(function() {
        var html = document && document.documentElement;
        var scrollTop = html.scrollTop || document.body.scrollTop;
        var main = document.body.getElementsByTagName("main")[0];

        fastdom.write(function() {
          this.scrollTop = scrollTop;

          html.classList.add("modal-open");
          html.scrollTop = 0;
          document.body.scrollTop = 0;

          _.each(main.children, function(mainChild) {
            mainChild.style.transform = "translate(0, " + (-scrollTop) + "px)";
          });
          _.each(thisElem.children, function(thisChild) {
            thisChild.style.transform = "";
          });
          thisElem.classList.add("open");
        });
      });
    }
  },
  unlockHTML: function() {
    if(dom.exists) {
      var thisElem = ReactDOM.findDOMNode(this);

      fastdom.read(function() {
        var html = document && document.documentElement;
        var newScrollTop = html.scrollTop || document.body.scrollTop;
        var main = document.body.getElementsByTagName("main")[0];

        fastdom.write(function() {
          html.classList.remove("modal-open");
          html.scrollTop = this.scrollTop;
          document.body.scrollTop = this.scrollTop;

          _.each(main.children, function(mainChild) {
            mainChild.style.transform = "";
          });
          _.each(thisElem.children, function(thisChild) {
            thisChild.style.transform = "translate(0, " + (-newScrollTop) + "px)";
          });
          thisElem.classList.remove("open");
        });
      });
    }
  }
};

var moment = require("moment");

module.exports = {
  getDiffString: function(a, b) {
    var diffString;
    var diff = moment.duration(a - b);
    if(diff < 60 * 1000)
      diffString = diff.seconds() + "s";
    else if(diff < 60 * 60 * 1000)
      diffString = diff.minutes() + "m " + diff.seconds() + "s";
    else if(diff < 24 * 60 * 60 * 1000)
      diffString = diff.hours() + "h " + diff.minutes() + "m";
    else
      diffString = diff.days() + "d " + diff.hours() + "h"; 
    return diffString;
  }
};
var CalendarDispatcher = require('../dispatchers/CalendarDispatcher');
var CalendarConstants = require('../constants/CalendarConstants');
var CalendarAPI = require("../api/CalendarAPI");

var GroupSearchDispatcher = require('../dispatchers/GroupSearchDispatcher');
var GroupSearchConstants = require('../constants/GroupSearchConstants');
var GroupSearchAPI = require("../api/GroupSearchAPI");

module.exports = {
  load: function() {
    CalendarDispatcher.dispatch({type: CalendarConstants.ActionTypes.LOAD_START});
    CalendarAPI.load(function(error, response) {
      if(error)
        return CalendarDispatcher.dispatch({type: CalendarConstants.ActionTypes.LOAD_FAIL, error: error});
      CalendarDispatcher.dispatch({type: CalendarConstants.ActionTypes.LOAD_SUCCESS, results: response.results});
    });
  },
  toggleWatching: function(showing) {
    var id = showing.get("id");
    var watching = showing.get("watching");
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.SET_WATCHING,
      id: id,
      watching: !watching
    });
    CalendarAPI.setWatching(id, !watching, function(error) {
      if(error) {
        CalendarDispatcher.dispatch({
          type: CalendarConstants.ActionTypes.SET_WATCHING,
          id: id,
          watching: watching
        });
      }
    });
  },
  toggleWatched: function(showing) {
    var id = showing.get("id");
    var epno = showing.getIn(["episode", "episode_number"]);
    var watched = showing.getIn(["episode", "watched"]);
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.SET_WATCHED,
      showing: showing,
      watched: !watched
    });
    CalendarAPI.setWatched(id, epno, !watched, function(error) {
      if(error) {
        CalendarDispatcher.dispatch({
          type: CalendarConstants.ActionTypes.SET_WATCHED,
          showing: showing,
          watched: watched
        });
      }
    });
  },
  editShowing: function(showing) {
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.OPEN_EDITOR,
      showing: showing
    });
  },
  closeShowingEditor: function() {
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.CLOSE_EDITOR
    });
  },
  hideGroupSearchResults: function() {
    GroupSearchDispatcher.dispatch({
      type: GroupSearchConstants.ActionTypes.SET_SHOW_RESULTS,
      show: false
    });
  },
  addGroup: function(showing, group) {
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.ADD_GROUP,
      id: showing.get("id"),
      group: group
    });
    GroupSearchDispatcher.dispatch({
      type: GroupSearchConstants.ActionTypes.RESET
    });
    CalendarAPI.addGroup(showing.get("id"), group.get("id"), function(error) {
      if(error) {
        CalendarDispatcher.dispatch({
          type: CalendarConstants.ActionTypes.REMOVE_GROUP,
          id: showing.get("id"),
          group: group
        });
      }
    });
  },
  removeGroup: function(showing, group) {
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.REMOVE_GROUP,
      id: showing.get("id"),
      group: group
    });
    CalendarAPI.removeGroup(showing.get("id"), group.get("id"), function(error) {
      if(error) {
        CalendarDispatcher.dispatch({
          type: CalendarConstants.ActionTypes.ADD_GROUP,
          id: showing.get("id"),
          group: group
        });
      }
    });
  },
  searchGroups: function(showing, term) {
    if(!term) {
      GroupSearchDispatcher.dispatch({type: GroupSearchConstants.ActionTypes.SEARCH_SUCCESS, results: []});
    } else {
      GroupSearchDispatcher.dispatch({type: GroupSearchConstants.ActionTypes.SEARCH_START, term: term});
      GroupSearchAPI.search(showing.get("id"), term, function(error, response) {
        if(error)
          return GroupSearchDispatcher.dispatch({type: GroupSearchConstants.ActionTypes.SEARCH_FAIL, error: error});
        GroupSearchDispatcher.dispatch({type: GroupSearchConstants.ActionTypes.SEARCH_SUCCESS, results: response.results});
      });
    }
  },
  editGroupAdder: function(showing, text) {
    CalendarDispatcher.dispatch({
      type: CalendarConstants.ActionTypes.EDIT_GROUP_ADDER,
      showing: showing,
      text: text
    });
    GroupSearchDispatcher.dispatch({
      type: GroupSearchConstants.ActionTypes.SET_SHOW_RESULTS,
      show: false
    });
  },
  updateMarker: function() {
    CalendarDispatcher.dispatch({type: CalendarConstants.ActionTypes.UPDATE_MARKER});
  }
};

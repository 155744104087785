var React = require("react");

var GroupSearchResult = React.createClass({
  propTypes: {
    result: React.PropTypes.object.isRequired,
    searchTerm: React.PropTypes.string,
    onMouseDown: React.PropTypes.func.isRequired,
    onMouseUp: React.PropTypes.func.isRequired
  },
  getDefaultProps: function() {
    return {
      searchTerm: "",
    };
  },
  render: function() {
    var group_name = this.props.result.get("name");
    var group_name_parts;
    if(this.props.searchTerm) {
      var search_term_index = group_name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase());
      if(search_term_index === -1) {
        group_name_parts = [group_name, "", ""];
      } else {
        group_name_parts = [
          group_name.substring(0, search_term_index),
          group_name.substring(search_term_index, search_term_index + this.props.searchTerm.length),
          group_name.substring(search_term_index + this.props.searchTerm.length)
        ];
      }
    }
    return (
      <div
        className="group-result"
        role="search result"
        tabIndex="1"
        aria-label="Search Result"
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}>
          <div className="result-name">
            {group_name_parts[0]}<strong>{group_name_parts[1]}</strong>{group_name_parts[2]}
          </div>
      </div>
    );
  },
  onMouseDown: function(event) {
    this.props.onMouseDown(event, this.props.result);
  },
  onMouseUp: function(event) {
    this.props.onMouseUp(event, this.props.result);
  }
});

module.exports = GroupSearchResult;

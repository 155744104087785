var PreferencesDispatcher = require('../dispatchers/PreferencesDispatcher');
var PreferencesConstants = require('../constants/PreferencesConstants');

module.exports = {
  save: function(preferences) {
    PreferencesDispatcher.dispatch({
      type: PreferencesConstants.ActionTypes.SAVE,
      preferences: preferences
    });
  },
  show: function() {
    PreferencesDispatcher.dispatch({type: PreferencesConstants.ActionTypes.SHOW});
  },
  hide: function() {
    PreferencesDispatcher.dispatch({type: PreferencesConstants.ActionTypes.HIDE});
  }
};

var _ = require("underscore");
var React = require("react");

var ModalMixin = require("./mixins/Modal");

var CalendarActionCreators = require("../actions/CalendarActionCreators");

var PreferencesStore = require("../stores/PreferencesStore");
var PreferencesActionCreators = require("../actions/PreferencesActionCreators");
var PreferencesConstants = require("../constants/PreferencesConstants");

var Preferences = React.createClass({
  mixins: [ModalMixin],
  _getState: function(newPreferences) {
    return {
      preferences: newPreferences || PreferencesStore.getPreferences(),
      isShowing: PreferencesStore.isShowing()
    };
  },
  getInitialState: function() {
    return this._getState();
  },
  componentWillMount: function() {
    if(this.state.showingBeingEdited)
      this.lockHTML();
  },
  componentDidMount: function() {
    PreferencesStore.addChangeListener(this.onStoreChange);
  },
  componentWillUnmount: function() {
    PreferencesStore.removeChangeListener(this.onStoreChange);
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    if(!this.state.isShowing && nextState.isShowing)
      this.lockHTML();
    else if(this.state.isShowing && !nextState.isShowing)
      this.unlockHTML();
    return true;
  },
  render: function() {
    var component = this;

    var methodElements = _.map(PreferencesConstants.MethodButtons, function(method) {
      var className = "button toggle-button icon-block-button method-button method-button-" + method.value;
      if(method.value === component.state.preferences.get(PreferencesConstants.Keys.METHOD))
        className += " toggled";
      var onMethodClick = function() {
        component.setState(component._getState(component.state.preferences.set(PreferencesConstants.Keys.METHOD, method.value)));
      };
      return <button
        key={method.value}
        type="button"
        className={className}
        onClick={onMethodClick}>
          <i className={method.iconClass}></i>
          {method.label}
        </button>;
    });

    var filterElements = _.map(PreferencesConstants.FilterButtons, function(filter) {
      var className = "button toggle-button icon-block-button filter-button filter-button-" + filter.value;
      if(filter.value === component.state.preferences.get(PreferencesConstants.Keys.FILTER))
        className += " toggled";
      var onFilterClick = function() {
        component.setState(component._getState(component.state.preferences.set(PreferencesConstants.Keys.FILTER, filter.value)));
      };
      return <button
        key={filter.value}
        type="button"
        className={className}
        onClick={onFilterClick}>
          <i className={filter.iconClass}></i>
          {filter.label}
        </button>;
    });

    var uiElements = _.map([{
      label: "Autoscroll to Today?",
      key: PreferencesConstants.Keys.SCROLL_TO_TODAY
    }], function(uiOption) {
      var className = "button toggle-button";
      if(component.state.preferences.get(uiOption.key))
        className += " toggled";
      var onUIClick = function() {
        component.setState(component._getState(component.state.preferences.set(uiOption.key, !component.state.preferences.get(uiOption.key))));
      };
      return <button
        key={uiOption.key}
        type="button"
        className={className}
        onClick={onUIClick}>
          {uiOption.label}
        </button>;
    });

    return (
      <div className="preferences modal">
        <div className="modal-mobile-buttons">
          <button type="button" className="modal-mobile-close button" onClick={this.hide}><i className="fa fa-close"></i> Close</button>
          <button type="button" className="modal-mobile-save button" onClick={this.save}><i className="fa fa-save"></i> Save</button>
        </div>
        <div className="modal-backdrop" onClick={this.hide}></div>
        <div className="modal-content clearfix">
          <button type="button" className="modal-desktop-close button icon-button" onClick={this.hide}><i className="fa fa-close"></i></button>
          <h3 className="strong header">Preferences</h3>
          <h5 className="preference-label">View...</h5>
          <div className="method-button-group">{methodElements}</div>
          <h5 className="preference-label">Filter by...</h5>
          <div className="filter-button-group">{filterElements}</div>
          <h5 className="preference-label">UI</h5>
          <div className="preferences-button-group">{uiElements}</div>
          <button type="button" className="modal-desktop-save button" onClick={this.save}><i className="fa fa-save"></i> Save</button>
        </div>
      </div>
    );
  },
  save: function() {
    var should_reload = this.state.preferences.get(PreferencesConstants.Keys.METHOD) !== PreferencesStore.getPreferences().get(PreferencesConstants.Keys.METHOD);
    PreferencesActionCreators.save(this.state.preferences);
    if(should_reload)
      CalendarActionCreators.load();
  },
  hide: function() {
    PreferencesActionCreators.hide();
  },
  onStoreChange: function() {
    this.setState(this._getState());
  }
});

module.exports = Preferences;
